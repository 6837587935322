import { daredropApiUrl } from 'constants/app'
import requestHandler from 'utils/requestHandler'
import { TwitchGame, StreamsStatsReportPayloadItem } from 'types'
import { getCookie } from 'cookies'
import { authNames } from 'constants/cookies'

export type RequestCreatorsPayload = {
    dateTo?: string
    dateFrom?: string
    timeRange?: string
    languages: string[]
    gameIds: string[]
    excludedGameIds: string[]
    minCcv?: number
    maxCcv?: number
    hasEmail?: boolean
    contacted?: boolean
    creatorIds?: string[]
    minStreamTimePercentage?: number
}

export const requestCreatorsReport = async (
    body: RequestCreatorsPayload
): Promise<{ body: Record<string, unknown> }> => {
    const { dateFrom, creatorIds, dateTo, languages, gameIds } = body

    if (!gameIds.length) {
        throw new Error('Games are missing')
    }
    if (!creatorIds?.length && !languages.length) {
        throw new Error('Creator ids or languages are missing')
    }
    if (!dateFrom || !dateTo) {
        throw new Error('Date is missing')
    }

    return requestHandler<{
        body: { data: TwitchGame[]; pagination: { cursor: string } }
    }>({
        url: `${daredropApiUrl}`,
        body: {
            endpointId: 'CREATOR_API_PROXY',
            payload: {
                body,
                url: `creators-report`,
                method: 'POST',
            },
            authentication: getCookie(authNames.ADMIN_APP_TOKEN),
        },
        method: 'POST',
        headers: {},
    })
}

type RequestCreatorsReportAltPayload = Omit<
    RequestCreatorsPayload,
    'creatorIds'
>

export const requestCreatorsReportAlt = async (
    body: RequestCreatorsReportAltPayload
) => {
    const { timeRange, gameIds, minStreamTimePercentage } = body
    if (!gameIds.length) {
        throw new Error('Games are missing')
    }
    if (!timeRange) {
        throw new Error('Date is missing')
    }

    return requestHandler<{
        body: { url: string }
    }>({
        url: `${daredropApiUrl}`,
        body: {
            endpointId: 'CREATOR_API_PROXY',
            payload: {
                body: {
                    ...body,
                    // parse percentage to decimal
                    ...(minStreamTimePercentage
                        ? {
                              minStreamTimePercentage:
                                  minStreamTimePercentage / 100,
                          }
                        : {}),
                },
                url: `creators-report-alt`,
                method: 'POST',
            },
            authentication: getCookie(authNames.ADMIN_APP_TOKEN),
        },
        method: 'POST',
        headers: {},
    })
}

export const getGameStats = async (body: RequestCreatorsReportAltPayload) => {
    const { timeRange, gameIds, minStreamTimePercentage } = body
    if (!gameIds.length) {
        throw new Error('Games are missing')
    }
    if (!timeRange) {
        throw new Error('Date is missing')
    }

    return requestHandler<{
        body: { count: number; averageViewers: number; followers: number }
    }>({
        url: `${daredropApiUrl}`,
        body: {
            endpointId: 'CREATOR_API_PROXY',
            payload: {
                body: {
                    ...body,
                    // parse percentage to decimal
                    ...(minStreamTimePercentage
                        ? {
                              minStreamTimePercentage:
                                  minStreamTimePercentage / 100,
                          }
                        : {}),
                },
                url: `games/stats`,
                method: 'POST',
            },
            authentication: getCookie(authNames.ADMIN_APP_TOKEN),
        },
        method: 'POST',
        headers: {},
    })
}

export type CreateStreamPayload = {
    averageViewers: number
    durationMinutes: number
    gameId: string
    gameName: string
    language: string
    startedAt: string
    endedAt: string
    title: string
    userId: string // platform ID
}

export const createStream = async (
    body: CreateStreamPayload
): Promise<{ body: Record<string, any> }> =>
    requestHandler<{
        body: { status: number }
    }>({
        url: `${daredropApiUrl}`,
        body: {
            endpointId: 'CREATOR_API_PROXY',
            payload: {
                body,
                url: `test-streams`,
                method: 'POST',
            },
            authentication: getCookie(authNames.ADMIN_APP_TOKEN),
        },
        method: 'POST',
        headers: {},
    })

export const generateStreamsStatsReport = async (
    filters: StreamsStatsReportPayloadItem[]
): Promise<{ body: Record<string, any> }> =>
    requestHandler<{
        body: { status: number }
    }>({
        url: `${daredropApiUrl}`,
        body: {
            endpointId: 'CREATOR_API_PROXY',
            payload: {
                body: { filters },
                url: 'streams-stats-report',
                method: 'POST',
            },
            authentication: getCookie(authNames.ADMIN_APP_TOKEN),
        },
        method: 'POST',
        headers: {},
    })

export const uploadCreators = async (usernames: string[]) =>
    requestHandler({
        url: `${daredropApiUrl}`,
        body: {
            endpointId: 'CREATOR_API_PROXY',
            payload: {
                body: usernames.map((username) => ({
                    username,
                    contacted: true,
                })),

                url: `creators`,
                method: 'PATCH',
            },
            authentication: getCookie(authNames.ADMIN_APP_TOKEN),
        },
        method: 'POST',
        headers: {},
    })
