import { createContext, CSSProperties, ReactNode, useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'

type Value = string | number | boolean | undefined | Record<string, any>

// TODO: fix this
export const FormContext = createContext({
    form: {} as Record<string, any>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleFormChange: (name: string, value: Value | Value[]): void => {
        //
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleMultipleFormChanges: (obj: Record<string, any>): void => {
        //
    },
})

type FormSubmitProps<T> = {
    onSubmit: (formData: T) => any
    clearForm: () => void
    setFormError: (error: string) => void
    disabled?: boolean
    isLoading?: boolean
    text?: string
}

const isError = (data: unknown): data is { error: unknown } =>
    typeof data === 'object' && data !== null && 'error' in data

const FormSubmit = <T,>({
    onSubmit,
    clearForm,
    setFormError,
    disabled = false,
    isLoading = false,
    text = 'SUBMIT',
}: FormSubmitProps<T>) => (
    <FormContext.Consumer>
        {({ form }) => (
            <div style={{ position: 'relative', height: 36.5 }}>
                <Button
                    disabled={disabled}
                    style={{
                        width: '100%',
                        backgroundColor: '#aaa',
                        position: 'absolute',
                        // marginBottom: 20,
                    }}
                    onClick={async () => {
                        try {
                            setFormError('')
                            const res = await onSubmit(form as T)

                            if (isError(res)) {
                                const { error } = res
                                if (error) {
                                    throw error
                                }
                            }

                            clearForm()
                        } catch (e) {
                            console.log(e)
                            if (e instanceof Error) {
                                setFormError(e.message)
                            }
                        }
                    }}
                >
                    {text}
                </Button>
                {isLoading && (
                    <CircularProgress
                        size={20}
                        style={{
                            position: 'absolute',
                            left: 'calc(50% - 10px)',
                            top: 'calc(50% - 10px)',
                        }}
                    />
                )}
            </div>
        )}
    </FormContext.Consumer>
)

type FormProps<T> = {
    children: ReactNode
    style?: CSSProperties
    submits: { onSubmit: (formData: T) => unknown; text?: string }[]
    defaults: Partial<Record<keyof T, unknown>>
    clear?: boolean
}

const Form = <T,>({
    children,
    submits,
    style,
    defaults = {},
    clear = true,
}: FormProps<T>) => {
    const [form, setForm] = useState(defaults)
    const [formError, setFormError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    return (
        <FormContext.Provider
            value={{
                form,
                handleFormChange: (name: string, value: Value | Value[]) => {
                    setForm({ ...form, [name]: value })
                },
                handleMultipleFormChanges: (obj: Record<string, any>) => {
                    setForm({ ...form, ...obj })
                },
            }}
        >
            <form style={style}>
                {children}
                {formError && (
                    <div style={{ color: '#f50057' }}>{formError}</div>
                )}
                {submits?.length &&
                    submits.length > 0 &&
                    submits.map((submit) => (
                        <FormSubmit
                            key={submit.text || 'SUBMIT'}
                            disabled={isLoading}
                            isLoading={isLoading}
                            onSubmit={async (formData: T) => {
                                try {
                                    setIsLoading(true)
                                    await submit.onSubmit(formData)
                                    // eslint-disable-next-line no-useless-catch
                                } catch (e) {
                                    throw e
                                } finally {
                                    setIsLoading(false)
                                }
                            }}
                            setFormError={setFormError}
                            clearForm={() => {
                                if (clear) {
                                    setForm(defaults)
                                }
                            }}
                            text={submit.text}
                        />
                    ))}
            </form>
        </FormContext.Provider>
    )
}
export default Form
