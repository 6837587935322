import { resourcesIds } from 'resources'
import { getOneKey, editOneItemIdKey, updateKey } from 'keys'
import { omit } from 'ramda'
import requestHandler from 'utils/requestHandler'
import { getCookie } from 'cookies'
import { authNames } from 'constants/cookies'
import { daredropApiUrl } from 'constants/app'

export default {
    [resourcesIds.MANAGE_CAMPAIGN]: {
        [getOneKey]: (id: string): Record<string, any> => ({
            endpointId: 'DROP_GET_EVENT',
            payload: {
                id,
            },
        }),
        [editOneItemIdKey]: (
            rawData: Record<string, any>[]
        ): Record<string, any> => rawData,
        [updateKey]: async (params: any): Promise<Record<string, any>> => {
            const { data, previousData } = params
            const { approved, activateInvoice, id } = data
            const { approved: prevApproved } = previousData

            if (activateInvoice || prevApproved === false) {
                await requestHandler({
                    url: `${daredropApiUrl}`,
                    body: {
                        endpointId: 'DROP_EDIT_EVENT',
                        payload: {
                            dropEventPayload: {
                                ...omit(['status', 'approved'], data),
                            },
                        },
                        authentication: getCookie(authNames.ADMIN_APP_TOKEN),
                    },
                    method: 'POST',
                    headers: {},
                })
            }

            if (activateInvoice) {
                await requestHandler({
                    url: `${daredropApiUrl}`,
                    body: {
                        endpointId: 'DROP_ACTIVATE_EVENT',
                        payload: {
                            id,
                            method: 'INVOICE',
                        },
                        authentication: getCookie(authNames.ADMIN_APP_TOKEN),
                    },
                    method: 'POST',
                    headers: {},
                })
            }
            if (prevApproved === false && approved) {
                await requestHandler({
                    url: `${daredropApiUrl}`,
                    body: {
                        endpointId: 'DROP_ADMIN_AUDIT',
                        payload: {
                            audit: 'ACTIVATE',
                            id,
                        },
                        authentication: getCookie(authNames.ADMIN_APP_TOKEN),
                    },
                    method: 'POST',
                    headers: {},
                })
            }

            return {
                endpointId: 'DROP_EDIT_EVENT',
                payload: {
                    dropEventPayload: {
                        ...omit(['status', 'approved'], data),
                    },
                },
            }
        },
    },
}
