// @ts-nocheck
import {
    SimpleForm,
    DateInput,
    Edit,
    Toolbar,
    SaveButton,
    useRecordContext,
    Button,
    useRedirect,
    BooleanInput,
    minValue,
    RadioButtonGroupInput,
    NumberInput,
    FormDataConsumer,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { Tooltip } from '@mui/material'
import requestHandler from 'utils/requestHandler'
import { getCookie } from 'cookies'
import { daredropApiUrl } from 'constants/app'
import moment from 'moment'
import { resourcesIds } from 'resources'
import { authNames } from 'constants/cookies'
import equals from 'ramda/src/equals'

enum PointsCalculationMethod {
    DEFAULT = 'default',
    CPVH = 'cpvh',
}

const SubmitToolbar = () => (
    <Toolbar>
        <SaveButton />
    </Toolbar>
)

const CampaignManagementForm = () => {
    const redirect = useRedirect()
    const activeKey = 'ACTIVE'
    const dropEvent = useRecordContext()
    const { setValue } = useFormContext()

    const {
        approved,
        status,
        subscriptionStatus,
        budget: { type: budgetType },
        id,
        brand,
    } = dropEvent

    const managementButtons = [
        {
            label: 'Enable',
            disabled: !approved || status === activeKey,
            tooltip: 'Campaign already enabled',
            endpointId: 'CHANGE_CAMPAIGN_STATUS',
            payload: {
                action: 'ENABLE',
                dropEventId: id,
                brandId: brand.id,
            },
        },
        {
            label: 'Disable',
            disabled: !approved || status !== activeKey,
            tooltip: 'Campaign already disabled',
            endpointId: 'CHANGE_CAMPAIGN_STATUS',
            payload: {
                action: 'DISABLE',
                dropEventId: id,
                brandId: brand.id,
            },
        },
        {
            label: 'Reset dares',
            disabled: false,
            tooltip: 'Campaign already disabled',
            endpointId: 'RESET_CAMPAIGN_DARES',
            payload: {
                id,
            },
        },
    ]

    return (
        <>
            <div style={{ display: 'flex' }}>
                {managementButtons.map(
                    ({ label, disabled, tooltip, endpointId, payload }) => (
                        <Tooltip
                            key={label}
                            title={disabled ? tooltip : ''}
                            placement="top"
                        >
                            <div>
                                <Button
                                    label={label}
                                    size="large"
                                    color="primary"
                                    disabled={disabled}
                                    onClick={async () => {
                                        const res = await requestHandler<{
                                            statusCode: number
                                            body?: Record<string, any>
                                            error?: Record<string, any>
                                        }>({
                                            url: daredropApiUrl,
                                            method: 'POST',
                                            body: {
                                                endpointId,
                                                payload,
                                                authentication: getCookie(
                                                    authNames.ADMIN_APP_TOKEN
                                                ),
                                            },
                                            headers: {},
                                        })
                                        const { statusCode } = res

                                        if (statusCode === 200) {
                                            redirect(
                                                `/${resourcesIds.CAMPAIGNS_RESOURCE_ID}`
                                            )
                                        }
                                    }}
                                />
                            </div>
                        </Tooltip>
                    )
                )}
            </div>
            {equals(approved, false) && (
                <BooleanInput label="Approve" source="approved" />
            )}
            {subscriptionStatus !== activeKey && budgetType === 'CAMPAIGN' && (
                <BooleanInput
                    label="Activate Invoice"
                    source="activateInvoice"
                />
            )}
            <BooleanInput
                label="Campaign should award points"
                source="awardPoints"
            />
            <BooleanInput
                label="Disable 'User has game' behavior"
                source="disableUserHasGame"
            />
            <DateInput
                label="Campaign expiration date"
                source="expirationDate"
                validate={minValue(moment().add(1, 'day').format('YYYY-MM-DD'))}
            />
            <RadioButtonGroupInput
                label="Points calculation method"
                source="pointsCalculationMethod"
                onChange={(e: Event) => {
                    if (e.target.value !== PointsCalculationMethod.CPVH) {
                        setValue('costPerViewHourPoints', undefined)
                    }
                    if (e.target.value === PointsCalculationMethod.CPVH) {
                        setValue('costPerViewHourPoints', 0)
                    }
                }}
                choices={[
                    {
                        id: PointsCalculationMethod.DEFAULT,
                        name: 'Default',
                    },
                    { id: PointsCalculationMethod.CPVH, name: 'CPVH' },
                ]}
            />
            <FormDataConsumer<{
                formData: { pointsCalculationMethod: PointsCalculationMethod }
            }>>
                {({ formData }) => {
                    return (
                        formData.pointsCalculationMethod ===
                            PointsCalculationMethod.CPVH && (
                            <NumberInput
                                label="Cost per view hour (in points)"
                                source="costPerViewHourPoints"
                            />
                        )
                    )
                }}
            </FormDataConsumer>
            {/* <DateInput
                validate={minValue(moment().add(1, 'day').format('YYYY-MM-DD'))}
                source="daresCountResetDate"
            /> */}
        </>
    )
}

const ManageCampaign = () => (
    <Edit
        redirect={`/${resourcesIds.CAMPAIGNS_RESOURCE_ID}`}
        mutationMode="pessimistic"
    >
        <SimpleForm toolbar={<SubmitToolbar />}>
            <CampaignManagementForm />
        </SimpleForm>
    </Edit>
)

export default ManageCampaign
