import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import {
    Edit,
    NumberInput,
    SaveButton,
    SimpleForm,
    Toolbar,
    useNotify,
    useRecordContext,
    useRedirect,
} from 'react-admin'
import { Reward } from 'resourceDescriptions/rewards'
import { resourcesIds } from 'resources'

const FormToolbar = () => {
    const { type } = useRecordContext<Reward>()
    return (
        <Toolbar>
            <SaveButton
                alwaysEnable
                icon={<ArrowDownwardIcon />}
                label={`Download and use ${
                    type === 'dropCode' ? 'codes' : 'keys'
                }`}
            />
        </Toolbar>
    )
}

const RewardsAmountForm = () => {
    const { available, type } = useRecordContext<Reward>()
    const helperText = `Available ${
        type === 'dropCode' ? 'codes' : 'keys'
    }: ${available}`
    const label = `${type === 'dropCode' ? 'Codes' : 'Keys'} amount`

    return (
        <SimpleForm toolbar={<FormToolbar />}>
            <NumberInput
                defaultValue={1}
                min={1}
                max={available}
                label={label}
                helperText={helperText}
                source="decryptAmount"
            />
        </SimpleForm>
    )
}

export const RewardAmountSelect = () => {
    const notify = useNotify()
    const redirect = useRedirect()

    const onSuccess = () => {
        notify(`Decrypting rewards`, { type: 'info', undoable: true })
        redirect(`/${resourcesIds.REWARDS_RESOURCE_ID}`)
    }

    const transformData = (data: Record<string, any>) => {
        const { available, decryptAmount } = data
        return {
            ...data,
            available: available - decryptAmount,
        }
    }

    return (
        <Edit mutationOptions={{ onSuccess }} transform={transformData}>
            <RewardsAmountForm />
        </Edit>
    )
}
