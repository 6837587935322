import { ComponentType, useState } from 'react'
import Form from 'components/Form'
import FilterAutocomplete from 'components/FilterAutocomplete'
import languageOptions from 'utils/languageOptions'
import FilterTwitchGamesAutocomplete from 'components/FilterTwitchGamesAutocomplete'
import { RadioField } from 'components/RadioField'
import { getGameStats } from 'utils/creatorApi'
import { YesNoFilter } from 'components/YesNoFilter'
import { InputOption } from 'types/forms'
import { CcvField } from 'components/CcvField'
import { PercentageField } from 'components/PercentageField'
import { onCreatorsReportSubmit } from '../creators-report-alt/RequestCreatorsReportFormAlt'

const DatePicker = () => (
    <RadioField
        source="timeRange"
        label="Date range"
        options={[
            {
                value: 7,
                label: '7 days',
            },
            {
                value: 14,
                label: '14 days',
            },
            {
                value: 30,
                label: '30 days',
            },
            {
                value: 60,
                label: '60 days',
            },
            {
                value: 90,
                label: '90 days',
            },
            {
                value: 180,
                label: '180 days',
            },
            {
                value: 365,
                label: '365 days',
            },
        ]}
    />
)

const style = {
    width: 280,
    margin: '20px auto',
    display: 'grid',
    gridGap: 20,
}

type RequestCreatorsReportAltFormData = {
    timeRange?: string
    languages: InputOption<string>[]
    gameIds: InputOption<string>[]
    excludedGameIds: InputOption<string>[]
    minCcv?: number
    maxCcv?: number
    hasEmail?: boolean
    contacted?: boolean
    minStreamTimePercentage?: number
}

const statItemStyle = {}

const GameStatsReportForm: ComponentType = () => {
    const [data, setData] = useState({
        averageViewers: 0,
        count: 0,
        followers: 0,
    })
    const [error, setError] = useState('')
    const onSubmit = async (formData: RequestCreatorsReportAltFormData) => {
        const res = await getGameStats({
            ...formData,
            excludedGameIds: formData.excludedGameIds?.map(
                (game) => game.value
            ),
            gameIds: formData.gameIds?.map((game) => game.value),
            languages: formData.languages?.map((language) => language.value),
        })
        if (res.body) {
            setData(res.body)
            setError('')
        } else {
            setError('Error')
        }
        return res
    }

    return (
        <div>
            <Form
                clear={false}
                style={style}
                submits={[
                    { onSubmit, text: 'Get Stats' },
                    { onSubmit: onCreatorsReportSubmit, text: 'Get Report' },
                ]}
                defaults={{ gameIds: [], languages: [], excludedGameIds: [] }}
            >
                <FilterTwitchGamesAutocomplete
                    label="Games"
                    source="gameIds"
                    multiple
                />
                <FilterTwitchGamesAutocomplete
                    label="Excluded games"
                    source="excludedGameIds"
                    multiple
                />
                <FilterAutocomplete
                    label="Languages"
                    source="languages"
                    options={languageOptions}
                />
                <CcvField />
                <PercentageField
                    source="minStreamTimePercentage"
                    label=">= % of Stream Time"
                />
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <DatePicker />
                    <div>
                        <YesNoFilter label="Has TikTok" source="hasTiktok" />
                    </div>
                </div>
            </Form>
            <div style={style}>
                <div style={statItemStyle}>
                    Number of Creators: {data.count.toLocaleString()}
                </div>
                <div style={statItemStyle}>
                    Est. watch time:{' '}
                    {Math.round(
                        data.averageViewers * 2 * data.count
                    ).toLocaleString()}
                </div>
                <div style={statItemStyle}>
                    Total followers: {data.followers.toLocaleString()}
                </div>
                {error}
            </div>
        </div>
    )
}

export default GameStatsReportForm
