import decode from 'jwt-decode'
import { z } from 'zod'
import { AuthApiGroup } from 'types'
import { authNames } from 'constants/cookies'
import { authApiUrl, daredropApiUrl, daredropUrl } from '../constants/app'
import requestHandler from './requestHandler'
import { getCookie } from '../cookies'

const adminGroups = ['admin', 'superAdmin'] satisfies AuthApiGroup[]

const authApiGroups = [
    'agency',
    'agencyAccountManager',
    'creator',
    'viewer',
    'brand',
    ...adminGroups,
] as const satisfies Readonly<AuthApiGroup[]>

export const authApiTokenSchema = z.object({
    groups: z.array(z.enum(authApiGroups)),
    email: z.string(),
    userId: z.string(),
    exp: z.number(),
})

type SignInResponse = { accessToken: string; refreshToken: string }

export const signIn = async ({
    email,
    password,
}: {
    email: string
    password: string
}) =>
    requestHandler<SignInResponse>({
        url: `${authApiUrl}/sign-in`,
        method: 'POST',
        body: {
            email,
            password,
        },
        headers: {},
    })

export const refreshAuthToken = (_refreshToken: string) =>
    requestHandler<Pick<SignInResponse, 'accessToken'>>({
        url: `${authApiUrl}/refresh-token`,
        method: 'POST',
        body: {
            refreshToken: _refreshToken,
        },
        headers: {},
    })

type SignInAsResponse =
    | {
          body: {
              accessToken: string
              refreshToken: string
          }
          error: never
      }
    | { error: unknown; body: never }

export const signInAs = async (userId: string) => {
    const { body, error } = await requestHandler<SignInAsResponse>({
        headers: {},
        url: daredropApiUrl,
        method: 'POST',
        body: {
            endpointId: 'AUTH_API_PROXY',
            payload: {
                url: 'internal/sign-in',
                method: 'POST',
                body: {
                    userId,
                },
            },
            authentication: getCookie(authNames.ADMIN_APP_TOKEN),
        },
    })

    if (error && !body) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw new Error(
            'Something went wrong, checkout console for more information'
        )
    }
    const { accessToken, refreshToken } = body
    const url = new URL(`${daredropUrl}/drops`)

    const hash = new URLSearchParams({
        accessToken,
        refreshToken,
    })

    url.hash = hash.toString()
    window.open(url.href, '_blank')
}

export const isTokenExpired = (token: { exp: number }) =>
    token.exp - Math.floor(Date.now() / 1000) <= 0

export const hasAdminGroups = (groups: AuthApiGroup[]) => {
    return groups.some((group) =>
        adminGroups.includes(group as (typeof adminGroups)[number])
    )
}

export const validateAndParseToken = (token: string | undefined) => {
    if (typeof token !== 'string') {
        throw new Error('Token is required')
    }

    const parsedToken = authApiTokenSchema.parse(decode(token))

    if (!hasAdminGroups(parsedToken.groups)) {
        throw new Error('Wrong permissions, try login again')
    }

    if (isTokenExpired(parsedToken)) {
        throw new Error('Session expired, try login again')
    }

    return parsedToken
}

export const getRequestToken = async () => {
    const token = getCookie(authNames.ADMIN_APP_TOKEN)

    if (token) {
        return token
    }

    const refreshToken = getCookie(authNames.ADMIN_APP_REFRESH_TOKEN)

    if (!refreshToken) {
        throw new Error('No refresh token found')
    }

    const { accessToken } = await refreshAuthToken(refreshToken)

    if (!accessToken) {
        throw new Error('Failed to refresh access token')
    }

    return accessToken
}
