import AsyncFilterAutocomplete from 'components/AsyncFilterAutocomplete'
import AutocompleteTag from 'components/AutocompleteTag'
import DateRangePicker from 'components/DateRangePicker'
import FieldNumeric from 'components/FieldNumeric'
import FilterAutocomplete from 'components/FilterAutocomplete'
import Form from 'components/Form'
import { RadioField } from 'components/RadioField'
import { YesNoFilter } from 'components/YesNoFilter'
import moment from 'moment-mini'
import { countries, dateRanges, videoCategories } from './fixtures'
import { useCreatorsReportYoutubeForm } from './hooks'

export const CreatorsReportYoutubeForm = () => {
    const {
        handleSubmit,
        handleGameSearch,
        handleTopicsSearch,
        handleTagsSearch,
    } = useCreatorsReportYoutubeForm()

    const staticDateRange = (
        <RadioField
            source="dateFrom"
            label="Date range"
            options={dateRanges}
            onChange={(formData) => {
                // eslint-disable-next-line no-param-reassign
                formData.dateTo = moment().utc().endOf('day').format()
            }}
        />
    )

    const style = {
        width: 280,
        margin: '0 auto',
        display: 'grid',
        gridGap: 20,
    }

    return (
        <div>
            <Form
                style={style}
                submits={[{ onSubmit: handleSubmit }]}
                defaults={{}}
            >
                <AsyncFilterAutocomplete
                    label="Games"
                    source="games"
                    multiple
                    asyncOptionsFn={handleGameSearch}
                />
                <AsyncFilterAutocomplete
                    label="Excluded Games"
                    source="excludedGames"
                    multiple
                    asyncOptionsFn={handleGameSearch}
                />
                <AutocompleteTag label="Keywords" source="keywords" />
                <AutocompleteTag
                    label="Excluded Keywords"
                    source="excludedKeywords"
                />
                <FilterAutocomplete
                    options={countries}
                    source="countries"
                    label="Countries"
                />
                <FilterAutocomplete
                    options={videoCategories}
                    source="categories"
                    label="Categories"
                />
                <AsyncFilterAutocomplete
                    label="Topics"
                    source="topics"
                    multiple
                    asyncOptionsFn={handleTopicsSearch}
                />
                <AsyncFilterAutocomplete
                    label="Video and Channel Tags"
                    source="tags"
                    multiple
                    asyncOptionsFn={handleTagsSearch}
                />
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <FieldNumeric
                        label="Subscribers (min)"
                        source="subscribersMin"
                    />
                    <FieldNumeric
                        label="Subscribers (max)"
                        source="subscribersMax"
                    />
                </div>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <FieldNumeric
                        label="Avg views (min)"
                        source="avgViewsMin"
                    />
                    <FieldNumeric
                        label="Avg views (max)"
                        source="avgViewsMax"
                    />
                </div>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <FieldNumeric
                        label="Channel views (min)"
                        source="channelViewsMin"
                    />
                    <FieldNumeric
                        label="Channel views (max)"
                        source="channelViewsMax"
                    />
                </div>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    {staticDateRange}
                    <div>
                        <YesNoFilter label="Contacted" source="contacted" />
                        <YesNoFilter label="Has Email" source="hasEmail" />
                        <YesNoFilter label="Has TikTok" source="hasTikTok" />
                    </div>
                </div>
                <DateRangePicker
                    sourceFrom="dateFrom"
                    sourceTo="dateTo"
                    label="Date range (manual)"
                />
            </Form>
        </div>
    )
}
