import { ReactElement } from 'react'
import { TextField as MuiTextField } from '@material-ui/core'
import { FormContext } from './Form'

const FieldNumeric = ({
    source,
    label,
}: {
    label?: string
    source: string
}): ReactElement => (
    <FormContext.Consumer>
        {({ form, handleFormChange }) => (
            <MuiTextField
                style={{ width: 130 }}
                label={label}
                type="number"
                variant="outlined"
                value={form[source] || 0}
                onChange={(e) => {
                    handleFormChange(source, Number(e.target.value))
                }}
            />
        )}
    </FormContext.Consumer>
)

export default FieldNumeric
