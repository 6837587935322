import { ReactElement } from 'react'
import { Chip, TextField as MuiTextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { FilterOption } from 'types'
import { FormContext } from 'components/Form'

export type AutocompleteTagProps = {
    source: string
    label?: string
    options?: FilterOption[]
    onChange?: (input: string) => void
    multiple?: boolean
}

const AutocompleteTag = ({
    source,
    label,
    onChange,
    multiple = true,
}: AutocompleteTagProps): ReactElement => (
    <FormContext.Consumer>
        {({ handleFormChange, form }) => (
            <Autocomplete
                options={[]}
                value={form[source] || []}
                onChange={(e, v) => handleFormChange(source, v)}
                multiple={multiple}
                getOptionLabel={(option) => option.label || ''}
                renderInput={(params) => (
                    <MuiTextField
                        {...params}
                        label={label}
                        variant="outlined"
                        onChange={(e) => onChange && onChange(e.target.value)}
                    />
                )}
                freeSolo
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={option}
                            label={option}
                            {...getTagProps({ index })}
                        />
                    ))
                }
            />
        )}
    </FormContext.Consumer>
)

export default AutocompleteTag
