import { Admin, Resource, CustomRoutes } from 'react-admin'
import { Route } from 'react-router-dom'
import dataProvider from 'dataProvider'
import authProvider from 'authProvider'
import browserHistory from 'browserHistory'
import RequestCreatorsReportForm from 'views/creators-report/RequestCreatorsReportForm'
import StreamsStatsReport from 'views/streams-stats-report/StreamsStatsReport'
import CampaignsList from 'views/campaigns/CampaignsList'
import CampaignCreatorDetails from 'views/campaign-points-details/CampaignPointsDetailsList'
import SignedUpCreators from 'views/signed-up-creators/SignedUpCreators'
import PointsMarketplaceItems from 'views/points-marketplace/PointsMarketplaceItems'
import EditMarketplaceItem from 'views/points-marketplace/EditMarketplaceItem'
import CreateMarketplaceItem from 'views/points-marketplace/CreateMarketplaceItem'
import AddStreamForm from 'views/campaign-streams/AddStreamForm'
import CampaignStreamsList from 'views/campaign-streams/CampaignStreamsList'
import CampaignTransactionsList from 'views/campaign-transactions/CampaignTransactionsList'
import { RewardsLits } from 'views/rewards/RewardsList'
import { RewardAmountSelect } from 'views/rewards/RewardAmountSelect'
import UsersList from 'views/users-list/UsersList'
import User from 'views/users-list/User'
import PreviousDaresList from 'views/previous-dares/PreviousDaresList'
import { resourcesIds } from 'resources'
import EditPointsAmount from 'views/campaign-points-details/EditPointsAmount'
import PointsTransactionsList from 'views/points-transactions/PointsTransactionsList'
import PointsStatistics from 'views/points-statistics/PointsStatistics'
import ManageCampaign from 'views/campaigns/ManageCampaign'
import logInAs from 'utils/logInAs'
import LayoutWithNotify from 'components/LayoutWithNotify'
import RequestCreatorsReportFormAlt from 'views/creators-report-alt/RequestCreatorsReportFormAlt'
import { DaredropApi } from 'utils/daredrop-api'
import { CreatorsReportYoutubeForm, CreatorsReportTiktokForm } from 'views'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { VerificationsList } from 'views/verifications/VerificationsList'
import { CreateVerification } from 'views/verifications/CreateVerification'
import { EditVerification } from './views/verifications/EditVerification'
import GameStatsReportForm from './views/game-stats-report/GameStatsReportForm'

const queryClient = new QueryClient()

const App = () => (
    <QueryClientProvider client={queryClient}>
        <Admin
            dataProvider={dataProvider}
            authProvider={authProvider}
            history={browserHistory}
            layout={(props) => <LayoutWithNotify {...props} />}
        >
            <Resource
                name={resourcesIds.CAMPAIGNS_RESOURCE_ID}
                list={
                    <CampaignsList
                        postPanelLinks={[
                            {
                                label: 'Streams',
                                resourceId:
                                    resourcesIds.CAMPAIGN_STREAMS_RESOURCE_ID,
                            },
                            {
                                label: 'Points Details',
                                resourceId:
                                    resourcesIds.CAMPAIGN_POINTS_DETAILS,
                            },
                            {
                                label: 'Transactions',
                                resourceId:
                                    resourcesIds.CAMPAIGN_TRANSACTIONS_RESOURCE_ID,
                            },
                            {
                                label: 'Manage',
                                resourceId: resourcesIds.MANAGE_CAMPAIGN,
                                noQuery: true,
                            },
                            {
                                label: 'Creators report (private)',
                                disabled: (record) => !record.isPrivate,
                                action: async (record) => {
                                    const filters =
                                        await DaredropApi.getPrivateDropCreatorsPayload(
                                            record.id
                                        )
                                    browserHistory.push({
                                        pathname: resourcesIds.CREATORS_REPORT,
                                        search: `?source=${JSON.stringify(
                                            filters
                                        )}`,
                                    })
                                },
                            },
                            {
                                label: 'Creator Short Links Report',
                                action: async (record) => {
                                    const { fileUrl } =
                                        await DaredropApi.getDropShortLinksMappingReport(
                                            record.id
                                        )
                                    window.open(fileUrl, '_blank')
                                },
                            },
                            {
                                label: 'Log in as publisher',
                                action: (record) =>
                                    logInAs({ userId: record.brand.id }),
                            },
                        ]}
                    />
                }
            />
            <Resource
                name={resourcesIds.CAMPAIGN_POINTS_DETAILS}
                list={<CampaignCreatorDetails />}
                edit={<EditPointsAmount />}
            />
            <Resource
                name={resourcesIds.SIGNED_UP_CREATORS_RESOURCE_ID}
                list={<SignedUpCreators />}
            />
            <Resource
                name={resourcesIds.CAMPAIGN_STREAMS_RESOURCE_ID}
                list={<CampaignStreamsList />}
                create={<AddStreamForm />}
            />
            <Resource
                name={resourcesIds.CAMPAIGN_TRANSACTIONS_RESOURCE_ID}
                list={<CampaignTransactionsList />}
            />
            <Resource
                name={resourcesIds.USERS_LIST_RESOURCE_ID}
                list={<UsersList />}
                edit={<User />}
            />
            <Resource
                name={resourcesIds.POINTS_MARKETPLACE_RESOURCE_ID}
                list={<PointsMarketplaceItems />}
                edit={<EditMarketplaceItem />}
                create={<CreateMarketplaceItem />}
            />
            <Resource
                name={resourcesIds.CREATORS_REPORT_ALT}
                create={<RequestCreatorsReportFormAlt />}
            />
            <Resource
                name={resourcesIds.POINTS_TRANSACTIONS_RESOURCE_ID}
                list={<PointsTransactionsList />}
            />
            <Resource
                name={resourcesIds.REWARDS_RESOURCE_ID}
                list={<RewardsLits />}
                edit={<RewardAmountSelect />}
            />
            <Resource
                name={resourcesIds.MANAGE_CAMPAIGN}
                // this is just a form resource - no list component
                edit={<ManageCampaign />}
            />
            <Resource
                name={resourcesIds.PREVIOUS_DARES_RESOURCE_ID}
                list={<PreviousDaresList />}
            />
            <Resource
                name={resourcesIds.VERIFICATIONS}
                create={<CreateVerification />}
                list={<VerificationsList />}
                edit={<EditVerification />}
            />
            <CustomRoutes>
                <Route
                    path={resourcesIds.CREATORS_REPORT}
                    element={<RequestCreatorsReportForm />}
                />
                <Route
                    path={resourcesIds.CREATORS_REPORT_ALT}
                    element={<RequestCreatorsReportFormAlt />}
                />
                <Route
                    path={resourcesIds.GAME_STATS_REPORT}
                    element={<GameStatsReportForm />}
                />
                <Route
                    path={resourcesIds.STREAMS_STATS_REPORT}
                    element={<StreamsStatsReport />}
                />
                <Route
                    path="/points/:recordId"
                    element={<CampaignCreatorDetails />}
                />
                <Route
                    path={resourcesIds.POINTS_STATISTICS}
                    element={<PointsStatistics />}
                />
                <Route
                    path={resourcesIds.CREATORS_REPORT_YOUTUBE}
                    element={<CreatorsReportYoutubeForm />}
                />
                <Route
                    path={resourcesIds.CREATORS_REPORT_TIKTOK}
                    element={<CreatorsReportTiktokForm />}
                />
            </CustomRoutes>
        </Admin>
    </QueryClientProvider>
)

export default App
