export const isProd = process.env.REACT_APP_ENV === 'production'
// eslint-disable-next-line no-nested-ternary
export const stackDomain = isProd
    ? 'daredrop.com'
    : process.env.REACT_APP_DOMAIN
    ? `dev.${process.env.REACT_APP_DOMAIN}`
    : 'dev.watt.tv'

export const daredropUrl = `https://${stackDomain}`
export const daredropApiUrl = `https://api.${stackDomain}`
export const authApiUrl = `https://authorization.${stackDomain}`

const daredropDomain = isProd ? 'daredrop.com' : 'dev.watt.tv'
export const creatorApiUrl = `https://creator-api.${daredropDomain}`
