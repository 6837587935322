import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import equals from 'ramda/src/equals'
import {
    Datagrid,
    EditButton,
    FunctionField,
    List,
    NumberField,
    TextField,
    TextInput,
    useRecordContext,
} from 'react-admin'
import { CodeReward, KeyReward } from 'resourceDescriptions/rewards'
import { camelCaseSpace } from 'utils/stringModification'

const rewardFilters = [
    <TextInput label="Reward Name (case sensitive)" source="name" key="name" />,
    <TextInput
        label="Brand Name (case sensitive)"
        source="brandName"
        key="brandName"
    />,
]

const ConditionalEditButton = () => {
    const record = useRecordContext()
    const label = record && record.type === 'dropKey' ? 'Use key' : 'Use code'
    return <EditButton icon={<ArrowDownwardIcon />} label={label} />
}

export const RewardsLits = () => (
    <List title="Rewards" filters={rewardFilters}>
        <Datagrid isRowSelectable={() => false}>
            <TextField label="Reward Name" source="name" />
            <FunctionField
                label="Brand Name"
                render={(record: CodeReward) => record.brand.name || '---'}
            />
            <FunctionField
                label="Region"
                render={(record: KeyReward) =>
                    camelCaseSpace(record.region) || '---'
                }
            />
            <FunctionField
                label="Platform"
                render={(record: KeyReward) => record.platform || '---'}
            />
            <FunctionField
                label="Key Type"
                render={(record: KeyReward) =>
                    record.typeReadable || equals(record.type, 'dropKey')
                        ? 'Retail'
                        : '---'
                }
            />
            <NumberField source="available" label="Available" />
            <NumberField source="used" label="Used" />
            <ConditionalEditButton />
        </Datagrid>
    </List>
)
